import { listRequest, createUpdateRequest } from "../../service/KeywordService";

/**
 *
 * @param {*} data  JSON DATA TO SEND TO SERVER
 * @returns API RESULT
 */
export const listAction = (data) => async (dispatch) => {
  const response = await dispatch(listRequest(data));
  console.log(response.status);
  if (response.status === 200 || response.status === 201) {
    console.log(response);
    return Promise.resolve(
      response.data !== null && response.data.result != null
        ? response.data
        : null
    );
  } else {
    return Promise.resolve(null);
  }
};

export const createUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(createUpdateRequest(requestBody));
  console.log(response);
  if (response.status === 200 || response.status === 201) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
