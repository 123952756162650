import React, { useState } from "react";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { USER_API_PATH } from "../../network/config/apiPaths";

import { axiosApi } from "../../network/service/config/AaxiosUtil";
import { useNavigate } from "react-router-dom";

function AddUser() {
  const navigate = useNavigate();

  const [roleList, setRoleList] = useState([
    { id: "1", name: "Super Admin" },
    { id: "2", name: "Admin" },
    { id: "3", name: "Employee" },
    { id: "4", name: "Organization Admin" },
    { id: "5", name: "Organization User" },
    { id: "6", name: "User" },
  ]);

  const [validation, setValidation] = useState({
    role_id: false,
    userName: false,
    phoneNumber: false,
    email: false,
  });
  const [inputList, setInputList] = useState({
    role_id: "",
    userName: "",
    phoneNumber: 0,
    email: "",
  });

  const onChangeEvent = (e) => {
    const { name, value } = e.target;
    console.log("name...", name);
    setInputList({
      ...inputList,
      [name]: value,
    });
  };
  const reset = () => {
    setInputList({
      ...inputList,
      role_id: "",
      userName: "",
      phoneNumber: 0,
      email: "",
    });
  };

  async function CreateUser() {
    let requestData = {
      name: inputList.userName,
      email: inputList.email,
      password: inputList.phoneNumber,
      role_id: inputList.role_id,
      status: "ACTIVE",
      phone: inputList.phoneNumber,
    };

    const data = await axiosApi.post(
      API_CONFIG.API_HOST + USER_API_PATH.ADD_USER,
      requestData,
      {
        headers: API_HEADERS,
      }
    );
    console.log(data);
    data.status === 200 && data.data.status
      ? navigate(-1)
      : alert("User creation Error.");
  }

  return (
    <>
      <div className="container-fluid dashboard d-flex flex-row justify-content-center">
        <div className="row m-5 w-50">
          <div className="col-12">
            <select
              className="form-control"
              name="role_id"
              value={inputList.role_id}
              onChange={onChangeEvent}
            >
              <option value={""}>{"--select role--"}</option>
              {roleList.map((item, i) => {
                return (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-12">
            <input
              className="form-control mt-4"
              name="userName"
              value={inputList.userName}
              placeholder="Name"
              type="text"
              onChange={onChangeEvent}
            />
          </div>

          <div className="col-12">
            <input
              type="number"
              placeholder="Phone number"
              name="phoneNumber"
              value={inputList.phoneNumber}
              onChange={onChangeEvent}
              className="form-control mt-4"
            />
          </div>
          <div className="col-12">
            <input
              type="email"
              name="email"
              value={inputList.email}
              onChange={onChangeEvent}
              className="form-control mt-4"
              placeholder="Email Id"
            />
          </div>
          <div className="col-12 text-center">
            <button className="btn btn-success w-25 mt-4" onClick={CreateUser}>
              Submit
            </button>
          </div>
        </div>
        {console.log(inputList)}
      </div>
    </>
  );
}

export default AddUser;
