import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formUpdateData,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { updateMessage } from "../../redux/slice/toastSlice";
import { changeStatus } from "../../redux/slice/loaderSlice";
import AppLodingBar from "../../components/loader";
import { axiosApi } from "../../network/service/config/AaxiosUtil";
import { API_CONFIG } from "../../network/config/ApiConfig";

const UpdateFilePath = (props) => {
  // TO get Callbacks
  const dispatch = useDispatch();
  const formData = useSelector(formUpdateData);
  const itemInfo = formData.item;
  const [isLoading, setIsLoading] = useState(false);
  // Page Form
  const [dataForm, setDataForm] = useState({
    name: "",
  });

  useEffect(() => {
    if (itemInfo) {
      let updateValues = {
        name: itemInfo.file_name,
      };

      setDataForm(updateValues);
    }
  }, []);

  const handleInputChanges = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmtClick = async () => {
    if (!dataForm.name) {
      dispatch(updateMessage({ display: true, message: "Enter all fields" }));
      return;
    }
    dispatch(changeStatus(true));
    let requestBody = {
      file_name: dataForm.name,
      file_status: "UPLOADED",
      status_message: "File Path Updated.",
    };

    // if (itemInfo != undefined) {
    //   requestBody.fileId = itemInfo.id;
    // }

    await axiosApi
      .post(
        API_CONFIG.API_HOST + "File/update?fileId=" + itemInfo.id,
        requestBody,
        {
          headers: {
            "api-access-key": API_CONFIG.ACCESS_KEY,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (props.onSubmit) props.onSubmit();
        } else {
          // FAILBLOCK block
          dispatch(
            updateMessage({
              display: true,
              message: "Error While updating info",
            })
          );
        }
      })
      .catch((error) => {
        console.log("File/upload ERROR", error);
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      });

    // await dispatch(createUpdateAction(requestBody)).then((response) => {
    //   console.log(response.status);
    //   console.log(response.message);
    //   if (response.status == true) {
    //     if (props.onSubmit) props.onSubmit();
    //   } else {
    //     dispatch(
    //       updateMessage({
    //         display: true,
    //         message: "Error While updating info",
    //       })
    //     );
    //   }
    // });
  };

  return (
    <>
      {/* {isLoading && <AppLodingBar />} */}
      <div
        style={{
          position: "fixed",
          backgroundColor: "rgba(0,0,0,0.5)",
          width: "100%",
          height: "100%",
          zIndex: "999",
          overflow: "scroll",
        }}
      >
        <div className="d-flex align-item-center justify-content-center">
          <div className="col-md-8">
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-5 card-1 p-5">
                  {itemInfo != undefined ? (
                    <h4 className="text-center">Update File Path</h4>
                  ) : (
                    <h4 className="text-center"> Add File Path</h4>
                  )}

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>File Path</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={dataForm.name}
                        onChange={(e) => handleInputChanges(e)}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 20,
                        }}
                      >
                        <button
                          style={{ display: "flex", justifyContent: "center" }}
                          type="button"
                          className="btn btn-purple-bg"
                          onClick={() => onSubmtClick()}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-white-bg"
                          onClick={() => {
                            dispatch(
                              updateFormInfo({
                                displayForm: false,
                                formData: {},
                              })
                            );
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateFilePath;
