import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { listAction } from "../../network/store/action/KeywordResponseAction";
import Select from "react-select";
import { API_CONFIG } from "../../network/config/ApiConfig";
import { axiosApi } from "../../network/service/config/AaxiosUtil";
import { Button, ProgressBar } from "react-bootstrap";

const UploadFile = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const displayOption = location.state != null ? location.state.name : "";
  const type = location.state != null ? location.state.type : "";
  const fileExt = location.state != null ? location.state.fileExt : "";

  const [resultList, setResultList] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [status, setStatus] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [progress, updateProgress] = useState(0);

  const [addUpdateData, setAddUpdateData] = useState({
    name: "",
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await dispatch(
      listAction(
        {
          secId: "",
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse != null && reponse.result) {
        setResultList(reponse.result);
        setSelectedOption(reponse.result[0]);
      } else {
        setResultList([]);
      }
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const resetData = () => {
    setAddUpdateData({
      name: "",
    });
    setSelectedFile(null);
  };

  const uploadNextChunk = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("file_type", type);
    formData.append("file_status", "UPLOADED");
    formData.append("status_message", "File Uplaoded to Server");
    formData.append("keyword_id", selectedOption.id);
    formData.append("name", addUpdateData.name);
    formData.append("uploaded_by", localStorage.getItem("userId"));
    formData.append("organization_id", localStorage.getItem("orgId"));

    // await postRequest("File/upload", formData, true).then((response) => {
    //   console.log("File/upload", response);
    // });

    await axiosApi
      .post(API_CONFIG.API_HOST + "File/upload", formData, {
        headers: {
          "api-access-key": API_CONFIG.ACCESS_KEY,
          "Content-Type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          console.log(`${loaded}kb of ${total}kb | ${percent}%`);
          // if (progress < 100)
          updateProgress(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setStatus("File/upload SUCCESS");
          console.log("File/upload SUCCESS", response);

          setTimeout(() => {
            updateProgress(100);
            resetData();
          }, 1000);
        } else {
          // FAILBLOCK block
          console.log("File/upload FAIL", response);
          setStatus("File/upload FAIL");
        }
      })
      .catch((error) => {
        console.log("File/upload ERROR", error);
        setStatus("File/upload ERROR");
      });
  };

  const updateInfo = async () => {
    let requestBody = {
      file_type: type,
      file_status: "PENDING",
      status_message: "File not uploaded to Server.",
      keyword_id: selectedOption.id,
      name: addUpdateData.name,
      uploaded_by: localStorage.getItem("userId"),
      organization_id: localStorage.getItem("orgId"),
    };

    await axiosApi
      .post(API_CONFIG.API_HOST + "File/updateInfo", requestBody, {
        headers: {
          "api-access-key": API_CONFIG.ACCESS_KEY,
          "Content-Type": "application/json",
        },

        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          console.log(`${loaded}kb of ${total}kb | ${percent}%`);
          // if (progress < 100)
          updateProgress(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setStatus("File/upload SUCCESS");
          console.log("File/upload SUCCESS", response);

          setTimeout(() => {
            updateProgress(100);
            resetData();
          }, 1000);
        } else {
          // FAILBLOCK block
          console.log("File/upload FAIL", response);
          setStatus("File/upload FAIL");
        }
      })
      .catch((error) => {
        console.log("File/upload ERROR", error);
        setStatus("File/upload ERROR");
      });
  };

  //- BK 03/08/24. If no file uploaded just send data and give option to update file name of uploaded entity.
  const handleFileUpload = () => {
    // if (!selectedFile) {
    //   alert("Please select a file to upload.");
    //   return;
    // }
    // setStatus("Upload inprogress");
    // uploadNextChunk();

    if (!selectedFile) {
      updateInfo();
    } else {
      setStatus("Upload inprogress");
      uploadNextChunk();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddUpdateData({
      ...addUpdateData,
      [name]: value,
    });
  };

  return (
    <>
      <main className="container-fluid dashboard">
        <div class="page-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4">{displayOption}</h4>

                    <form>
                      <div class="col-lg-12">
                        <label for="formrow-inputState" class="form-label">
                          Title
                        </label>
                        <input
                          className="form-control"
                          name="name"
                          type="text"
                          placeholder="Title"
                          value={addUpdateData.name}
                          onChange={handleChange}
                        />
                        <div class="mb-3">
                          <label for="formrow-inputState" class="form-label">
                            Select Keyword*
                          </label>
                          <Select
                            options={resultList}
                            value={selectedOption}
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => `${option.id}`}
                            onChange={(sOption) => {
                              console.log("selectedOptions", sOption);
                              setSelectedOption(sOption);
                            }}
                            // isMulti
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="mt-3 mb-3">
                            <label for="formFile" class="form-label">
                              PMC XML upload*
                            </label>
                            <input
                              class="form-control"
                              type="file"
                              id="formFile"
                              accept={fileExt}
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        {progress <= 0 || progress >= 100 ? (
                          <>
                            <Button onClick={handleFileUpload}>
                              Upload File
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}

                        {status !== "" ? <>{status}</> : <></>}
                      </div>
                    </form>

                    {progress > 0 ? (
                      <ProgressBar
                        style={{ margin: "20px" }}
                        striped
                        now={progress}
                        label={`${progress}%`}
                      />
                    ) : (
                      <></>
                    )}

                    {progress >= 100 ? (
                      <>
                        <div class="col text-center">
                          <Button
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UploadFile;
