import { FUPLOADS_API_PATH, KEYWORD_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/PostRequest";

export const listRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${FUPLOADS_API_PATH.LIST_PATH}?${data}`,
      null,
      false
    );
    return result;
  } catch (error) {
    console.log(error);
    // TODO Handle Error
  }
};

export const createRequest = (data) => async () => {
  try {
    let result = await postRequest(FUPLOADS_API_PATH.CREATE_PATH, data, false);
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const downloadRequest = (data) => async () => {
  try {
    var path = `${FUPLOADS_API_PATH.EXPORT_PATH}`;
    if (data !== "") {
      path = `${FUPLOADS_API_PATH.EXPORT_PATH}?${data}`;
    }
    let result = await getRequest(path, null, false);
    return result;
  } catch (error) {
    console.log(error);
    // TODO Handle Error
  }
};
