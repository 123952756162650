export const USER_API_PATH = {
  LOGIN: "validateUser",
  ALL_USER_LIST: "all-users",
  USER_LIST: "userList",
  ADD_USER: "user",
  UPDATE_USER: "User/update",
  UPDATE_USER_PWD: "User/updatePassword",
  USER_INFO: "User/info?id=",
};

export const KEYWORD_API_PATH = {
  LIST_PATH: "Keyword/list",
  CREATE_UPDATE_PATH: "Keyword/update",
  DELETE_PATH: "Keyword/delete?id=",
};

export const FUPLOADS_API_PATH = {
  LIST_PATH: "File/list",
  CREATE_PATH: "File/upload",
  UPDATE_PATH: "File/update?fileId=",
  DELETE_PATH: "File/delete?id=",
  EXPORT_PATH: "File/export",
};

export const BRANCH_API_PATH = {
  LIST_PATH: "Organization/list",
  CREATE_UPDATE_PATH: "Organization/update",
};
