import {
  listRequest,
  loginRequest,
  createUpdateAction,
} from "../../service/UserService";

/**
 * Login Action
 *
 * @param {*} data  JSON DATA TO SEND TO SERVER
 * @returns API RESULT
 */
export const loginAction = (requestData) => async (dispatch) => {
  const response = await dispatch(loginRequest(requestData));
  console.log(response);
  if (response.status === 200 || response.status === 201) {
    await localStorage.setItem("authToken", response.data.result.id);
    await localStorage.setItem("userId", response.data.result.id);
    await localStorage.setItem("userRoleId", response.data.result.role_id);
    await localStorage.setItem("userName", response.data.result.name);
    await localStorage.setItem("userEmail", response.data.result.email);
    await localStorage.setItem("userPhone", response.data.result.phone);
    await localStorage.setItem(
      "superuserId",
      response.data.result.super_user_id
    );
    await localStorage.setItem("orgId", response.data.result.organization_id);

    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
export const listAction = () => async (dispatch) => {
  const response = await dispatch(listRequest());
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
