import { useEffect, useState } from "react";
import { listAction } from "../../../network/store/action/FileUploadResponseAction";
import {
  deleteStatus,
  formUpdateStatus,
  updateFormInfo,
} from "../../../redux/slice/formUpdateSlice";
import FileItemRow from "../file-item";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UpdateFilePath from "../update-file-path-form";

const XmlFiles = (props) => {
  const navigate = useNavigate();
  // list of items
  const [resultList, setResultList] = useState([]);
  const showRequestForm = useSelector(formUpdateStatus);
  const showDeleteAlert = useSelector(deleteStatus);

  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    await dispatch(listAction("file_type=XML", dispatch)).then((reponse) => {
      setFilterList([]);
      setLoading(false);
      if (reponse != null && reponse.result) {
        setResultList(reponse.result);
      } else {
        setResultList([]);
      }
    });
  };

  return (
    <>
      {showRequestForm && (
        <UpdateFilePath
          onSubmit={() => {
            loadData();
          }}
        />
      )}
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">XML Files</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  navigate("/uploadFile", {
                    state: {
                      name: "Upload XML Files",
                      type: "XML",
                      fileExt: "text/xml",
                    },
                  });
                }}
              >
                Upload XML file
              </button>
            </div>
          </div>
        </div>

        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              {/* <label>Search</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={searchVal}
                placeholder="Enter keyword"
                onChange={(e) => handleSearchClick(e)}
              /> */}
            </div>
          </div>

          <div className="col-md-12 card-1">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">keyword</th>
                  <th scope="col">Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Message</th>
                  <th scope="col">File Name</th>
                  <th scope="col">Emails Count</th>
                  <th scope="col">Uploaded By</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <>Loading ..</>
                ) : resultList && resultList.length > 0 ? (
                  <>
                    {resultList.map((item, index) => {
                      return <FileItemRow item={item} key={item.id} />;
                    })}
                  </>
                ) : (
                  <> No Data</>
                )}
                {/* {resultList.map((item, i) => {
                  return <FileItemRow item={item} key={item.id} />;
                })} */}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
};

export default XmlFiles;
