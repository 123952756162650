import React, { useEffect, useState } from "react";
import UserListItem from "./user-item";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { USER_API_PATH } from "../../network/config/apiPaths";
import { useNavigate } from "react-router-dom";

function UserList() {
  const navigate = useNavigate();
  const [apiResult, setApiResult] = useState([]);

  const [searchVal, setSearchVal] = useState("");
  const [roleList, setRoleList] = useState([
    { id: "1", name: "Super Admin" },
    { id: "2", name: "Admin" },
    { id: "3", name: "Employee" },
    { id: "4", name: "Organization Admin" },
    { id: "5", name: "Organization User" },
    { id: "6", name: "User" },
  ]);
  const [filterList, setFilterList] = useState([]);

  const [filterOptions, setfilterOptions] = useState({
    roleId: "",
  });

  useEffect(() => {
    UserList();
  }, []);

  const UserList = async () => {
    const data = await axios.get(
      API_CONFIG.API_HOST + USER_API_PATH.USER_LIST,
      {
        headers: API_HEADERS,
      }
    );
    setApiResult(data.data.result);
    setFilterList(data.data.result);
  };

  const handleChanges = (e) => {
    const searchTerm = e.target.value;
    setfilterOptions(searchTerm);
    console.log(searchTerm);
    const filterByRole = apiResult.filter((item) => {
      if (item.roleName.includes(searchTerm)) {
        return item;
      }
    });
    setFilterList(filterByRole);
  };

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    console.log("search Value", searchTerm);
    const filterBySearch = apiResult.filter((item) => {
      if (item.phone.includes(searchTerm)) {
        return item;
      }
    });
    setFilterList(filterBySearch);
  }

  apiResult.length === 0 && <span>Loading</span>;
  {
    console.log(apiResult);
  }
  return (
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="row justify-content-around tble">
          <div className="col-6">
            <h3 className="dashboard-title">Users</h3>
          </div>
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn btn-purple-bg"
              onClick={() => {
                navigate("/addNewUser");
              }}
            >
              + Add User
            </button>
          </div>
        </div>
      </div>
      <div className="row m-3">
        {/* <div className="col-md-3">
          <div className="form-group">
            <label>Search</label>
            <input
              type="number"
              name="name"
              className="form-control"
              value={searchVal}
              onChange={handleSearchClick}
              placeholder="Enter Phone No"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Role</label>
            <select
              name="roleId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={filterOptions.roleId}
            >
              <option value={""}>{"--Select Role--"}</option>
              {roleList &&
                roleList.length > 0 &&
                roleList.map((item, index) => {
                  return (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div> */}
        <div className="col-md-12 card-1">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Sno</th>
                <th scope="col">Name</th>
                <th scope="col">Contact no</th>
                <th scope="col">Role</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>

            <tbody>
              {filterList.map((item, index) => {
                return <UserListItem item={item} key={item.id} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}

export default UserList;
