import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Protected from "./pages/protected-route";
import Login from "./auth/login";
import KeywordList from "./pages/keyword/keyword";
import FileUpload from "./pages/test/fup";
import Dashboard from "./pages/dashboard/dashboard";
import TxtFiles from "./pages/uploads/txt/txtFiles";
import UploadFile from "./pages/uploads/add-update-form";
import PmcFiles from "./pages/uploads/pmc/pmcFiles";
import XmlFiles from "./pages/uploads/xml/xmlFiles";
import ExtractFiles from "./pages/uploads/efs/extractFiles";
import UserList from "./pages/users/user";
import AddUser from "./pages/users/add-new-user";
import OrgList from "./pages/Store/Store";
import StoreAddUpdateForm from "./pages/Store/store-add-update";
import OrganizationDetails from "./pages/Store/OrgDetails";
import FileUploadChunk from "./pages/test/fupchunk_notused";

export default function Routers() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/login" />} />

      <Route
        path="/dashboard"
        element={<Protected>{<Dashboard />}</Protected>}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/keywords" element={<KeywordList />} />

      <Route path="/uploadFile" element={<UploadFile />} />
      {/* <Route path="/uploadFile" element={<FileUploadChunk />} /> */}
      <Route path="/txt" element={<TxtFiles />} />
      <Route path="/pmc" element={<PmcFiles />} />
      <Route path="/fxml" element={<XmlFiles />} />
      <Route path="/efs" element={<ExtractFiles />} />
      <Route path="/users" element={<UserList />} />
      <Route path="/addNewUser" element={<AddUser />} />
      <Route path="/orgs" element={<OrgList />} />
      <Route path="/scu" element={<StoreAddUpdateForm />} />
      <Route path="/orgInfo" element={<OrganizationDetails />} />
    </Routes>
  );
}
