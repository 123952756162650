import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import OrgUserList from "../users/OrgUsers";

function OrganizationDetails(props) {
  const location = useLocation();
  const itemInfo = location.state != null ? location.state.item : undefined;
  const [item, setItem] = useState(itemInfo);

  const navigate = useNavigate();

  return (
    <>
      <div>
        {/* <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">{item.name}</h3>

              <label>{item.address}</label>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-danger"
                onClick={() => navigate(-1)}
              >
                Close
              </button>
            </div>
          </div>
        </div> */}
        <div className="row m-3 mt-0">
          <div className="col-12 mt-4">
            <Tabs
              defaultActiveKey="users"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="users" title="Users">
                <OrgUserList orgId={item.id} />
              </Tab>
              <Tab eventKey="keywords" title="Keywords"></Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrganizationDetails;
