import { useEffect, useRef, useState } from "react";
import { FaEye, FaImage, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  deleteStatus,
  displayRemoveAlert,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../network/store/action/KeywordResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";
import noImage from "../../assets/no_image.jpg";
import { API_CONFIG } from "../../network/config/ApiConfig";
import { KEYWORD_API_PATH } from "../../network/config/apiPaths";

const KeywordItem = (props) => {
  const [item, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const dispatch = useDispatch();

  const editItem = () => {
    console.log(item);
    dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
  };

  const deleteItem = () => {
    // console.log(item);
    dispatch(
      displayRemoveAlert({
        displayRemove: true,
        formData: { item: item },
        type: "Keyword",
        deleteApiPath: KEYWORD_API_PATH.DELETE_PATH,
      })
    );
  };

  const onStatusChange = async () => {
    let status = "ACTIVE";
    if (item.status == "DISABLED") {
      status = "ACTIVE";
    } else {
      status = "DISABLED";
    }

    let requestBody = {
      status: status,
      id: item.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        setItem(item, (item.status = status));
        setitemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <tr key={item.id}>
      {/* <th scope="row">{od.id}</th> */}
      <td className="align-middle">
        <p>{item.id}</p>
      </td>
      <td className="align-middle">
        <p>{item.name}</p>
      </td>

      <td className="align-middle">
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "ACTIVE" ? true : false}
          />
        </div>
      </td>
      <td className="align-middle">
        <p className="col-lg-12 d-flex justify-content-center">
          <span
            className="edit-icon-btn"
            onClick={() => {
              editItem();
            }}
          >
            <FaRegEdit />
          </span>{" "}
          <span
            className="delete-icon-btn"
            onClick={() => {
              deleteItem();
            }}
          >
            <FaTrash />
          </span>
        </p>
      </td>
    </tr>
  );
};

export default KeywordItem;
