import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";

function UserListItem(props) {
  const user = props.item;

  return (
    <>
      <tr>
        <td> {user.id} </td>
        <td>
          <Link to="/userProfile">{user.name}</Link>
        </td>
        <td> {user.phone} </td>
        <td> {user.roleName} </td>

        <td className="align-middle">
          <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              checked={user.status === "ACTIVE" ? true : false}
            />
          </div>
        </td>

        <td className="align-middle">
          <div className="d-flex justify-content-center">
            <span className="edit-icon-btn">
              <FaEdit />
            </span>
            <span className="delete-icon-btn">
              <FaTrash />
            </span>
          </div>
        </td>
      </tr>
    </>
  );
}

export default UserListItem;
