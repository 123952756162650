import { useEffect, useRef, useState } from "react";
import {
  FaEye,
  FaFileDownload,
  FaFileUpload,
  FaImage,
  FaRegEdit,
  FaRegFileCode,
  FaTrash,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { axiosApi } from "../../network/service/config/AaxiosUtil";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { FUPLOADS_API_PATH } from "../../network/config/apiPaths";
import { updateFormInfo } from "../../redux/slice/formUpdateSlice";

const FileItemRow = (props) => {
  const [item, setItem] = useState(props.item);
  const [mailCount, setMailCount] = useState(item.email_count);
  const [status, setStatus] = useState(item.file_status);
  const [isLoading, setLoading] = useState(
    status === "IN-PROGRESS" ? true : false
  );
  const dispatch = useDispatch();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "data.csv";
    axiosApi
      .get(
        API_CONFIG.API_HOST +
          FUPLOADS_API_PATH.EXPORT_PATH +
          "?file_id=" +
          item.id,
        {
          responseType: "blob",
        },
        API_HEADERS
      )
      .then((res) => {
        link.href = URL.createObjectURL(
          new Blob([res.data], { type: "text/csv" })
        );
        link.click();
      });
  };

  const pathUpdate = () => {
    console.log(item);
    dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
  };

  const extract = async () => {
    let requestBody = {
      fileId: item.id,
      extension: item.file_type,
      fileName: item.file_name,
      userId: item.uploaded_by,
      keywordId: item.keyword_id,
      organizationId: item.organization_id,
    };
    setLoading(true);
    setStatus("IN-PROGRESS");
    await axiosApi
      .post(API_CONFIG.API_HOST + "File/extract", requestBody, {
        headers: {
          "api-access-key": API_CONFIG.ACCESS_KEY,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.status === 200 || response.status === 201) {
          console.log("File/extract SUCCESS", response);
          setMailCount(response.data.result.email_count);
          setStatus(response.data.result.file_status);
        } else {
          // FAILBLOCK block
          console.log("File/extract FAIL", response);
        }
      })
      .catch((error) => {
        console.log("File/extract ERROR", error);
      });
  };

  return (
    <tr key={item.id}>
      {/* <th scope="row">{od.id}</th> */}
      <td className="align-middle">
        <p>{item.id}</p>
      </td>
      <td className="align-middle">
        <p>{item.keywordName}</p>
      </td>

      <td className="align-middle">
        <p>{item.name}</p>
      </td>

      <td className="align-middle">
        <p>{status}</p>
      </td>

      <td className="align-middle">
        <p>{item.status_message}</p>
      </td>

      <td className="align-middle">
        <p>{item.file_name}</p>
      </td>

      <td>{mailCount}</td>

      <td className="align-middle">
        <p>{item.uploadedUser}</p>
      </td>

      <td className="align-middle">
        {isLoading === false ? (
          <>
            {status === "PENDING" && (
              <p className="col-lg-12 d-flex justify-content-center">
                <span
                  className="edit-icon-btn"
                  onClick={() => {
                    pathUpdate();
                  }}
                >
                  <FaFileUpload />
                </span>{" "}
              </p>
            )}

            {status === "UPLOADED" && (
              <p className="col-lg-12 d-flex justify-content-center">
                <span
                  className="edit-icon-btn"
                  onClick={() => {
                    extract();
                  }}
                >
                  <FaRegFileCode />
                </span>{" "}
              </p>
            )}
            {status === "EXTRACTED" && (
              <p className="col-lg-12 d-flex justify-content-center">
                <span
                  className="edit-icon-btn"
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  <FaFileDownload />
                </span>{" "}
              </p>
            )}
          </>
        ) : (
          <>
            <p>Processing..</p>
          </>
        )}
      </td>
    </tr>
  );
};

export default FileItemRow;
